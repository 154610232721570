<template>
  <div class="px-10">
    <div align="center">
      <v-col cols="10">
        <v-progress-linear :value="valueDeterminate" height="30">
          <strong>ตั้งค่าสำเร็จ {{ Math.ceil(valueDeterminate) }}%</strong>
        </v-progress-linear>
      </v-col>
    </div>
    <v-row class="mb-7">
      <v-col cols="2"><div class="text-h6">1. ตั้งค่าสาขา</div></v-col>
      <v-col cols="4"
        ><div class="text-h6">2. เปิดใช้งาน พ.ร.บ./ประกันภัย</div></v-col
      >
      <v-col cols="2"><div class="text-h6">3. ตั้งค่าธุรกิจ</div></v-col>
      <!-- <v-col cols="2"
        ><div class="text-h5 primary--text">4. ตั้งค่าเอกสาร</div></v-col
      > -->
      <v-col cols="2"><div class="text-h6">4. ราคาสินค้า</div></v-col>
    </v-row>
    <div align="center" class="text-h5 mb-4 red--text">
      กรอกข้อมูล เท่าที่ต้องการใช้
    </div>

    <!-- <v-divider class="mb-5" /> -->

    <div class="text-h4 primary--text mb-5">4. ตั้งค่าเอกสาร</div>
    <v-row>
      <v-col cols="6">
        <!-- <div>
          แสดงค่าปรับภาษีรถ แยกต่างหาก
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span></span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_late_tax_fine"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group> -->
        <div class="pl-15">
          <div>
            แสดงจำนวนเงิน ที่รับมา
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span></span>
            </v-tooltip>
          </div>
          <v-radio-group
            class="mt-0"
            row
            v-model="form.setting.show_payment_amount"
          >
            <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
            <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
          </v-radio-group>

          <div>
            แสดงจำนวน เงินทอน
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" small v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span></span>
            </v-tooltip>
          </div>
          <v-radio-group
            class="mt-0"
            row
            v-model="form.setting.show_remaining_amount"
          >
            <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
            <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
          </v-radio-group>
        </div>
      </v-col>

      <v-col cols="6">
        <div>
          แสดงวันคุ้มครองของ พ.ร.บ. ตอนปริ้นเอกสาร
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span></span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_3rd_insurance_protection_date"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงวันคุ้มครองของ ประกันภัย ตอนปริ้นเอกสาร
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span></span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_insurance_protection_date"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>

        <div>
          แสดงเบอร์โทรลูกค้าในใบเสร็จ ตอนปริ้นเอกสาร
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span></span>
          </v-tooltip>
        </div>
        <v-radio-group
          class="mt-0"
          row
          v-model="form.setting.show_customer_phone_number"
        >
          <v-radio name="" label="เปิดใช้งาน" :value="true"></v-radio>
          <v-radio name="" label="ปิดใช้งาน" :value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-card width="100%">
          <v-card-title class="light">
            <v-row justify="space-around">
              <v-col cols="1"></v-col>
              <v-col cols="3" class="text-center">เอกสาร</v-col>
              <!-- <v-col cols="3" class="text-center">หัวเอกสาร</v-col> -->
              <v-col cols="8" class="text-center">หมายเหตุ</v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pt-5">
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6">ใบเสนอราคา</v-col>
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.quotation.header" v-model="form.document_setting.quotation.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.quotation.note"
                  v-model="form.document_setting.quotation.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6">ใบแจ้งหนี้</v-col>
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.invoice.header" v-model="form.document_setting.invoice.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.invoice.note"
                  v-model="form.document_setting.invoice.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                />
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6"
                >ใบเสร็จรับเงิน</v-col
              >
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.receipt.header" v-model="form.document_setting.receipt.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.receipt.note"
                  v-model="form.document_setting.receipt.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                /> </v-col
              ><v-col cols="2"></v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6">ใบลดหนี้</v-col>
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.credit_note.header" v-model="form.document_setting.credit_note.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.credit_note.note"
                  v-model="form.document_setting.credit_note.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                /> </v-col
              ><v-col cols="2"></v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6">ใบเพิ่มหนี้</v-col>
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.debit_note.header" v-model="form.document_setting.debit_note.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.debit_note.note"
                  v-model="form.document_setting.debit_note.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                /> </v-col
              ><v-col cols="2"></v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-15" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2"></v-col>
              <v-col cols="4" class="primary--text text-h6"
                >ใบกำกับภาษี/ใบเสร็จ (สำหรับบริษัทที่แยกบิล)</v-col
              >
              <!-- <v-col cols="3" class="text-center">
                <v-text-field name="document_setting.tax_invoice.header" v-model="form.document_setting.tax_invoice.header" label="ใส่ข้อมูลเพื่อเปลี่ยนชื่อหัวเอกสาร" dense outlined hide-details=""/>
              </v-col> -->
              <v-col cols="4" class="text-center">
                <v-textarea
                  name="document_setting.tax_invoice.note"
                  v-model="form.document_setting.tax_invoice.note"
                  label="ใส่ข้อมูลเพื่อเพิ่มหมายเหตุเอกสาร"
                  dense
                  outlined
                  hide-details=""
                /> </v-col
              ><v-col cols="2"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ย้อนกลับ</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >ถัดไป</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      document_setting: {
        credit_note: { header: '', note: '' },
        debit_note: { header: '', note: '' },
        invoice: { header: '', note: '' },
        quotation: { header: '', note: '' },
        receipt: { header: '', note: '' },
        tax_invoice: { header: '', note: '' },
      },
      setting: {
        show_late_tax_fine: false,
        show_payment_amount: false,
        show_remaining_amount: false,
        show_3rd_insurance_protection_date: false,
        show_insurance_protection_date: false,
        show_customer_phone_number: true,
      },
    },
    loading: false,
    branch_id: 0,
    setup_wizard: 0,
    branchs: [],
    valueDeterminate: 80,
  }),
  created() {
    this.getBranchs();
    this.getCheck();
  },
  methods: {
    async getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          let check = res.result;
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async close() {
      let fill = {
        branch_id: this.form._id,
        setup_wizard: 3,
      };
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/set_setup_wizard`, body)
        .then((res) => {
          this.$router.push({ name: 'setup-business' });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },

    getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          this.branchs = res.result;

          this.getBranch();
        });
    },
    async getBranch() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.loading = true;

      if (this.form.setup_wizard != 4) {
        this.form.setup_wizard = this.form.setup_wizard;
      } else {
        this.form.setup_wizard = 5;
      }
      this.form = { branch_id: this.form._id, ...this.form };
      delete this.form._id;
      let body = {
        token: this.$jwt.sign(this.form, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/setup_wizard4`, body)
        .then((res) => {
          this.$router.push({
            name: 'setup-price-product',
            params: { id: this.$route.params.id },
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
      console.log('save()');
      this.loading = false;
    },
  },
};
</script>
